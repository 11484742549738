@import 'variables.scss';

button:focus {
  outline: none;
}

.toolbar-collapsed {
  min-height: 0 !important;
  height: 0 !important;
  transition: .3s;
}

@media screen {
  .sidenav-container {
    height: calc(100vh - 170px);
    transition: .5s;

    .mat-sidenav .mat-drawer-inner-container {
      overflow-y: hidden;
      display: grid !important;
    }
  }

  .sidenav-content {
    display: flex;
    height: calc(100vh - 170px) !important;
    flex-direction: column;
    transition: .3s;
    // padding: 1em;
    padding-bottom: 4em;
    overflow-x: hidden;
  }

  .sidenav-container-max {
    height: calc(100vh - 95px) !important;
    transition: .3s;
  }

  .sidenav-content-max {
    height: calc(100vh - 95px) !important;
    transition: .5s;
  }

  .toolbar,
  .mat-toolbar {
    transition: .3s;
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    min-height: 80px;
    padding: 0 !important;


    .menu button {
      margin-left: 6px;
      margin-right: 10px;
    }

    .menu .mat-icon {
      color: $als-blue !important;
    }

    h1,
    .toolbar-title {
      font-size: 1.3em !important;
      font-weight: 400 !important;
      white-space: wrap;
    }
  }
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  margin-left: 26px !important;
  margin-right: 14px !important;
}

.toolbar .menu {
  width: 80px;
  display: flex;
  justify-content: center;
}

.mat-sidenav-collapse-transition {
  transition: width 1s ease;
}

.mat-sidenav-collapsed {
  width: 74px;
  transition: 1s ease;
  /* Adjust this value as needed */
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 48px;
}

.sidenav-content-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  max-height: unset !important;
  overflow: hidden;
}

.d-grid {
  display: grid;
}
