// $page-color: #fafafa;
$page-color: #fafafa;
$header-color: #0f4058;
$nav-hover: #1d304f;
$accept-color: #41f274;
$denied-color: #f25341;
$warning-color: #fff41f;
$completed-color: #4988f5;
$highlite-color: #8d9aae;
$hover-color: #bdd7f0;
$info-button: #69b4cf;

$dark-lbnl-blue: #00395A;
$als-blue: #006BA6;
$als-blue-15op: rgba(0, 107, 166, 0.15);
$dark-als-blue: #00587C;
$medium-als-blue: #0085CA;
$light-als-blue: #00B5E2;

$als-red: #E04E39;
$als-red-new: #CC0000;
$als-orange: #D57800;
$als-yellow: #EAAA00;
$als-green: #74AA50;
$als-teal: #007681;
$als-blue-blue: #4298B5;
$als-purple: #5D4777;
$als-burgundy: #672E45;
$als-black: #212529;
$als-dark-grey: #63666A;
$als-light-grey: #B1B3B3;
$als-light-grey-15op: #d8d9d9;

$res-status-active: #0044CC;
$res-status-commissioning: #EAAA00;
$res-status-decommissioned: #000000;
$res-status-inactive: #525252;

$disabled: #E0E0E0;
$als-white: #FFF;

/*
    Color guide for buttons:
    Cancel buttons: should use the property color="warn" to color them with $als-red
    Ok, Accept, Submit buttons: should use the property color="primary" to color them with $als-blue
    Save, Update, Edit: should use the property color="accent" to color them with $als-yellow

    Accept: primary
    Cancel: warn
*/