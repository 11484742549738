// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@import "./variables.scss";
@import "./ascc-theme.scss";
@import "./ascc-styles.scss";
@import "./colors.scss";
@import "./nav.scss";
@import "./quill-helper.scss";

body {
  background-color: $page-color !important;
  padding: 0
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

// mat-mdc-table Styles

table {
  border-collapse: collapse;
  width: 100%;
  //height: 100%;
}

td {
  border-collapse: collapse;
  font-size: .8em;
}

td.mat-mdc-cell:first-of-type,
td.mat-footer-cell:first-of-type,
th.mat-mdc-header-cell:first-of-type {
  padding-left: 10px !important;
}

td.mat-mdc-cell:last-of-type,
td.mat-footer-cell:last-of-type,
th.mat-mdc-header-cell:last-of-type {
  padding-right: 10px !important;
}

th.mat-sort-header-sorted {
  color: black;
}



.mat-row-highlite .mat-mdc-cell {
  color: white !important
}

tr.mat-row {
  height: 38px;
}

.mat-mdc-header-row {
  height: 35px !important;
  //background-color: $als-light-grey;
  background-color: #eee;
}

.mat-mdc-header-cell {
  color: white;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
}

.mat-mdc-form-field {
  width: 100% !important;
}

.filter {
  font-size: .8em !important;
}

.mat-row-highlite {
  background: $als-blue;
  opacity: 1;
  color: white;
}

.fit-content {
  height: 100%;
  display: flex;
  margin: auto;
  padding: 0.5em;
}

// // scrollbar
// .scrollbar {
//   background: rgba(0, 0, 0, 0);
//   overflow-y: auto;
//   scrollbar-color: rgba(0, 181, 226, .4) lightgray;
//   scrollbar-width: thin;
// }

// .scrollbar {
//   scrollbar-color: rgba(0, 181, 226, .4) lightgray;
//   scrollbar-width: thin;
// }

// .scrollbar::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
//   background-color: #fff;
//   border-radius: 5px;
// }

// .scrollbar::-webkit-scrollbar {
//   width: 5px;
//   height: 5px;
//   background-color: #fff;
// }

// .scrollbar::-webkit-scrollbar-thumb {
//   border-radius: 5px;
//   background-color: rgba(0, 181, 226, .4);
//   opacity: .5;
// }

.overflowX-hidden {
  overflow-x: hidden;
}

table.ascc thead tr th,
table.ascc-alternate thead tr th {
  font-weight: bolder;
  text-align: center;
  background-color: #CAEBED;
  border-color: #FFF;
}

table.ascc tbody tr.ascc-click,
table.ascc-alternate tbody tr.ascc-click {
  cursor: pointer;
}

table.ascc tbody tr td,
table.ascc-alternate tbody tr td {
  text-align: center;
  border-color: #FFF;
}

table.ascc tbody tr td {
  background-color: #fff;
}

table.ascc tbody tr.ascc-highlight td {
  background-color: #E7F4F6;
}

table.ascc-alternate tbody tr:nth-child(odd) {
  background-color: #E7F4F6;
}

table.ascc-alternate tbody tr:nth-child(even) {
  background-color: #CAEBED;
}

table.ascc tbody tr.ascc-selected td,
table.ascc-alternate tbody tr.ascc-selected td {
  background-color: #26A1FF;
  color: #FFF;
}

.mat-select-disabled .mat-select-value {
  color: #000 !important;
}

.tooltips {
  background-color: #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, .7);
}

.tooltips .arrow:after {
  border-bottom-color: #eee;
  box-shadow: 6px 5px 9px -9px black, 5px 6px 9px -9px black;
}

.tooltips .popover-body {
  color: #222;
}

.spinner {
  color: $light-als-blue;
  opacity: .5;
}

.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  height: 16px !important;
  width: 16px !important;
  margin-right: 1em;
}

.loading svg {
  height: 16px !important;
  width: 16px !important;
}

.blinking {
  animation: blinkingText 1.2s infinite;
}

@keyframes blinkingText {
  0% {
    color: #F00;
  }

  30% {
    color: #FF0
  }

  70% {
    color: #FF0
  }

  100% {
    color: #F00;
  }
}

.hidden {
  visibility: hidden;
  display: block;
  // height: 0 !important;
  // width: 0 !important;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 2000;
}

.btn-link {
  cursor: pointer;
}


.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: $als-blue !important;
}

.dropdown-menu {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
}

.hilited-input .mdc-notched-outline__trailing,
.hilited-input .mdc-notched-outline__notch,
.hilited-input .mdc-notched-outline__leading {
  background-color: #FFBDB3;
}

// .hilited-input.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-flex .mdc-notched-outline__leading,
// .hilited-input.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-flex .mdc-notched-outline__notch,
// .hilited-input.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-flex .mdc-notched-outline__trailing {
//   background: #FFBDB3;
// }


.mat-checkbox-frame {
  border-width: 1px !important;
}

.mat-radio-button~.mat-radio-button {
  margin-left: 16px;
}

.mat-checkbox {
  margin: 0 6px;
}

@page {
  size: 8.5in 10.5in;
  margin-left: 0.2in;
  margin-right: 0.2in;
}

@media print {

  .print-width {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .noprint {
    display: none;
  }

}

.display-none {
  display: none !important;
}

.badge-active {
  background-color: $res-status-active;
  color: white;
}

.badge-commissioning {
  background-color: $res-status-commissioning;
  color: white;
}

.badge-decommissioned {
  background-color: $res-status-decommissioned;
  color: white;
}

.badge-inactive {
  background-color: $res-status-inactive;
  color: white;
}

.res-status-color-active {
  color: $res-status-active !important;
}

.res-status-color-commissioning {
  color: $res-status-commissioning !important;
}

.res-status-color-decommissioned {
  color: $res-status-decommissioned !important;
}

.res-status-color-inactive {
  color: $res-status-inactive !important;
}

.h7 {
  font-size: 12px;
}

.card-disabled {
  color: #cdcdcd;
}

.colorDisabled {
  color: #afafaf;
}

.fakeA {
  cursor: pointer;
}

.ta-wkcenter {
  text-align: -webkit-center;
}

.cdk-global-scrollblock {
  position: initial !important;
  overflow: hidden !important;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.date {
  font-weight: 500;
  color: $als-green;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

mat-row,
mat-footer-row {
  min-height: 36px !important;
}


.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #fff !important;
}