@import './variables.scss';

.green {
  color: $als-green !important;

  a {
    color: $als-green !important;
  }
}

.red {
  color: $als-red !important;

  a {
    color: $als-red !important;
  }
}

.yellow {
  color: $als-yellow !important;

  a {
    color: $als-yellow !important;
  }
}

.blue {
  color: $als-blue !important;

  a {
    color: $als-blue !important;
  }
}

.blue-link {
  color: $als-blue-blue !important;

  a {
    color: $als-blue-blue !important;
  }
}

.cian {
  color: $als-teal !important;

  a {
    color: $als-teal !important;
  }
}

.orange {
  color: $als-orange !important;

  a {
    color: $als-orange !important;
  }
}

.gray {
  color: #aaa !important;

  a {
    color: #aaa !important;
  }
}

.purple {
  color: $als-purple !important;

  a {
    color: $als-purple !important;
  }
}

.burgundy {
  color: $als-burgundy !important;

  a {
    color: $als-burgundy !important;
  }
}

.pink {
  color: rgb(255, 85, 136) !important;

  a {
    color: rgb(255, 85, 136) !important;
  }
}

.black {
  color: $als-black !important;

  a {
    color: $als-black !important;
  }
}

.white {
  color: #FFF !important;
}

.green-background,
.green-bg {
  background-color: $als-green !important;
  color: white !important;
}

.red-background,
.red-bg {
  background-color: $als-red !important;
  color: white !important;
}

.yellow-background,
.yellow-bg {
  background-color: $als-yellow !important;
  color: white !important;
}

.blue-background,
.blue-bg {
  background-color: $als-blue !important;
  color: white !important;
}

.cian-background,
.cian-bg {
  background-color: $als-teal !important;
  color: white !important;
}

.orange-background,
.orange-bg {
  background-color: $als-orange !important;
  color: white !important;
}

.gray-background,
.gray-bg {
  background-color: #aaa !important;
  color: white !important;
}

.purple-background,
.purple-bg {
  background-color: $als-purple !important;
  color: white !important;
}

.burgundy-background,
.burgundy-bg {
  background-color: $als-burgundy !important;
  color: white !important;
}

.pink-background,
.pink-bg {
  background-color: rgb(255, 85, 136) !important;
  color: white !important;
}

.black-background,
.black-bg {
  background-color: $als-black;
  color: white !important;
}

.white-background,
.white-bg {
  background-color: #FFF;
}


//Dimmed
.green-background-dimmed,
.green-bg-dimmed {
  background-color: $als-green !important;
  color: white !important;
}

.red-background-dimmed,
.red-bg-dimmed {
  background-color: $als-red !important;
  color: white !important;
}

.yellow-background-dimmed,
.yellow-bg-dimmed {
  background-color: $als-yellow !important;
  color: white !important;
}

.blue-background-dimmed,
.blue-bg-dimmed {
  background-color: $als-blue !important;
  color: white !important;
}

.cian-background-dimmed,
.cian-bg-dimmed {
  background-color: $als-teal !important;
  color: white !important;
}

.orange-background-dimmed,
.orange-bg-dimmed {
  background-color: $als-orange !important;
  color: white !important;
}

.gray-background-dimmed,
.gray-bg-dimmed {
  background-color: #aaa !important;
  color: white !important;
}

.purple-background-dimmed,
.purple-bg-dimmed {
  background-color: $als-purple !important;
  color: white !important;
}

.burgundy-background-dimmed,
.burgundy-bg-dimmed {
  background-color: $als-burgundy !important;
  color: white !important;
}

.pink-background-dimmed,
.pink-bg-dimmed {
  background-color: rgb(255, 85, 136) !important;
  color: white !important;
}

.black-background-dimmed,
.black-bg-dimmed {
  background-color: $als-black;
  color: white !important;
}

.tooltip-white .mdc-tooltip__surface {
  --mdc-plain-tooltip-container-color: white;
  color: $als-black !important;
  font-size: 0.8em;
  border: 1px solid $als-black;
}

.tooltip-blue .mdc-tooltip__surface {
  --mdc-plain-tooltip-container-color: white;
  color: $als-blue !important;
  font-size: 0.8em;
  border: 1px solid $als-blue;
}

.tooltip-red .mdc-tooltip__surface {
  --mdc-plain-tooltip-container-color: white;
  color: $als-red !important;
  font-size: 0.8em;
  border: 1px solid $als-red;
}

.tooltip-green .mdc-tooltip__surface {
  --mdc-plain-tooltip-container-color: white;
  color: $als-green !important;
  font-size: 0.8em;
  border: 1px solid $als-green;
}

.tooltip-yellow .mdc-tooltip__surface {
  --mdc-plain-tooltip-container-color: white;
  color: $als-yellow !important;
  font-size: 0.8em;
  border: 1px solid $als-yellow;
}