@import './variables.scss';
// @import '~@angular/cdk/text-field/text-field';

//Color substitution
// :host ::ng-deep.mat-raised-button.mat-primary,
// .mat-mini-fab.mat-primary {
//   background-color: $als-blue;
//   color: white;
// }

:host ::ng-deep.mat-mdc-icon-button.mat-primary {
  color: $als-blue;
}

:host ::ng-deep.mat-mdc-icon-button.mat-primary:disabled,
.mat-stroked-button.mat-primary:disabled,
.mat-raised-button.mat-primary:disabled,
.mat-mini-fab:disabled,
.mat-mini-fab.mat-primary:disabled {
  background-color: rgba(0, 0, 0, .12);
  color: rgba(0, 0, 0, .26);
}

:host ::ng-deep.mat-button.mat-primary {
  color: $als-blue;
}

//----------------------------------------------

// //General Styles
// p {
//   font-family: Roboto;
//   font-size: 14px;
//   line-height: 1.33;
//   color: $als-black;
//   margin-bottom: auto !important;
// }

//----------------------------------------------

//Printing styles
@-moz-document url-prefix() {
  @page {
    margin-top: 0mm;
    margin-bottom: 0mm;
    margin-left: 0mm;
    margin-right: 0mm;
  }
}

body {
  height: calc(100vh - 90px) !important;
}

@media print {
  body {
    min-width: unset !important;
  }

  .mat-sidenav-content {
    width: auto;
    height: auto;
    overflow: visible;
    margin-left: unset !important;
  }

  .sidenav-content {
    height: auto !important;
  }

  .sidenav-container {
    height: auto !important;
  }

  .no-break-before {
    break-before: always;
    page-break-before: always;
  }

  .no-break-after {
    break-after: always;
    page-break-after: always;
  }

  .no-break-inside {
    -webkit-column-break-inside: avoid-page;
    /* Chrome, Safari, Opera */
    page-break-inside: avoid;
    /* Firefox */
    break-inside: avoid-page;
    /* IE 10+ */
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    border: 1px solid #757575;
  }

  .mat-checkbox-checkmark {
    fill: $als-black !important
  }

  .mat-checkbox-checkmark-path {
    stroke: $als-black !important
  }

  .mat-checkbox-mixedmark {
    background-color: $als-black !important
  }

  .checkbox {
    ::ng-deep.mdc-checkbox__background {
      width: 22px !important;
      height: 22px !important;
      position: relative;
      top: -2px;
      left: -2px;
      border: 1px solid !important;

      .mdc-checkbox__checkmark {
        display: none;
      }
    }
  }

  .mat-radio-inner-circle {
    box-shadow: 0 0 0 1000px $als-black inset !important;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle,
  .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
    border-color: $als-black;
  }

  //Size equivalence
  //col-12 = 100%, col-11 = 91.66666667%, col-10 = 83.33333333%, col-9 = 75%, col-8 = 66.66666667%, col-7 = 58.33333333%, col-6 = 50%
  //col-5 = 41.66666667%, col-4 = 33.33333333%, col-3 = 25%, col-2 = 16.66666667%, col-1 = 8.33333333%

  .p-8per {
    flex: 0 0 8.33333333% !important;
    max-width: 8.33333333% !important;
  }

  .p-16per {
    flex: 0 0 16.66666667% !important;
    max-width: 16.66666667% !important;
  }

  .p-25per {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  .p-33per {
    flex: 0 0 33.33333333% !important;
    max-width: 33.33333333% !important;
  }

  .p-41per {
    flex: 0 0 41.66666667% !important;
    max-width: 41.66666667% !important;
  }

  .p-50per {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .p-58per {
    flex: 0 0 58.33333333% !important;
    max-width: 58.33333333% !important;
  }

  .p-66per {
    flex: 0 0 66.66666667% !important;
    max-width: 66.66666667% !important;
  }

  .p-75per {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }

  .p-100per {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .no-print {
    display: none !important;
  }
}

//----------------------------------------------
//Design for mat-mdc-tables
.mat-mdc-table,
table.mat-mdc-table {
  // border: solid 1px $als-light-grey;
  box-shadow: none !important;
}

.mat-container {
  border: solid 1px $als-blue !important;
  overflow: auto;
  max-height: calc(100vh - 340px);
}

// mat-table {
//   border: 1px solid $als-blue !important;
// }

.mat-mdc-header-cell,
th.mat-mdc-header-cell {
  background-color: $als-blue !important;
  font-weight: 500 !important;
  text-align: left;
  color: white;
  padding: 0.6em !important;
}

.mat-mdc-header-cell:has(button.mat-mdc-icon-button) {
  padding: 0 0 0 20px !important;
}

.mat-mdc-header-cell .mat-mdc-icon-button {
  height: 30px;
  width: 30px;
  padding: 3px;
}

.mat-mdc-header-cell .mat-mdc-icon-button .mat-mdc-button-persistent-ripple,
.mat-mdc-header-cell .mat-mdc-icon-button .mat-mdc-focus-indicator,
.mat-mdc-header-cell .mat-mdc-icon-button .mat-mdc-button-ripple {
  display: none !important;
}

.mat-mdc-header-cell .mat-mdc-icon-button .mat-mdc-button-touch-target {
  height: 30px;
  width: 30px;
}

@media only screen and (max-width: 1300px) {

  .mat-mdc-header-cell,
  th.mat-mdc-header-cell {
    padding-top: 0.2em !important;
    padding-bottom: 0.2em !important;
  }
}

.mat-row,
tr.mat-row {
  border-bottom: solid 1px $als-light-grey;
  transition: .3s;
}

.mat-footer-row,
.mat-row {
  // display: inline-flex;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  min-height: fit-content;
}

.mat-mdc-header-row,
tr.mat-mdc-header-row {
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: fit-content;
}

.mat-mdc-row:hover,
tr.mat-mdc-row:hover {
  background-color: $hover-color;
  cursor: pointer;
}

.mat-mdc-cell,
td.mat-mdc-cell {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  padding: 0 0.6em !important;
}

.mat-row-highlight {
  background-color: lighten($als-blue, 50) !important;
}

tr.table-row:hover {
  background-color: $hover-color;
  cursor: pointer;
  color: #FFF !important;
  transition: .3s;
}

//----------------------------------------------

//Mat-mini-fab style
button.mat-mini-fab {
  box-shadow: none;
}

//----------------------------------------------

//Input mat-input styles
:host ::ng-deep.mat-mdc-form-field-appearance-outline {
  font-family: Roboto;
  font-size: 12px;
  line-height: 1.17;
  letter-spacing: 0.11px;
}

:host ::ng-deep.mat-mdc-form-field-appearance-outline.mat-mdc-form-field-disabled .mat-mdc-form-field-outline {
  color: rgba(33, 37, 41, 0.3);
}

:host ::ng-deep.mat-input-element:disabled {
  color: $als-black;
}

//This changes the color of the labels
:host ::ng-deep .mat-mdc-form-field-label {
  color: $als-dark-grey !important;
}

:host ::ng-deep.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
  color: $als-black;
}

:host ::ng-deep.mat-mdc-form-field-appearance-outline.mat-focused .mat-mdc-form-field-outline {
  color: $als-blue;
}

:host ::ng-deep.mat-mdc-form-field-outline-start {
  width: 8px !important;
}

label.mdc-label {
  margin: 0 !important;
}

.mat-mdc-radio-button .mdc-form-field .mdc-label {
  margin-bottom: .5em !important;
}

.mat-mdc-form-field {
  height: fit-content;
  margin-bottom: .5em;
}

.mat-mdc-form-field.filter,
.mat-mdc-form-field.filter input {
  width: 100%;
}

:host ::ng-deep .home-page-filter .mat-mdc-form-field-infix {
  margin: 5px;
  padding: 0;
}

// :host ::ng-deep .filter .mat-mdc-form-field-infix{
//     margin: 5px;
//     padding: 0;
// }
//----------------------------------------------

//Mat-checkbox style
:host ::ng-deep.mat-checkbox-checked:not(.mat-checkbox-disabled) .mat-checkbox-background {
  background-color: $als-blue;
}

:host ::ng-deep.mat-checkbox-checked:not(.mat-checkbox-disabled) .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled) .mat-ripple-element {
  background: $als-blue;
}

//----------------------------------------------

//Icon's style
.search-icon {
  opacity: 60%;
}

.noOutline {
  outline: none;
}

//----------------------------------------------

//Design for titles of side panels
.catalog-title {
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: $als-black;
  margin-top: .5em;
  margin-bottom: .8em;
}

//----------------------------------------------
/* Firefox (uncomment to work in Firefox, although other properties will not work!)  */
* {
  scrollbar-width: thin;
  scrollbar-color: $als-blue #fafafa;
}

/* Hover effect for Firefox */
*::-webkit-scrollbar-thumb:hover,
*::-moz-scrollbar-thumb:hover {
  background-color: #007bff;
  /* Replace $als-blue with actual value */
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #fafafa;
}

*::-webkit-scrollbar-track:hover {
  background-color: #FFF;

}

*::-webkit-scrollbar-track:active {
  background-color: #FFF;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $als-light-grey;
  border: 1px solid #fafafa;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: $als-blue;
}

*::-webkit-scrollbar-thumb:active {
  background-color: $als-blue-blue;
}

.scrollbarX {
  overflow-x: auto;
}

.scrollbarXY {
  overflow-y: scroll;
  overflow-x: scroll;
}


// #style-15 {
//   scrollbar-color: rgba(0, 181, 226, .4) rgb(231, 231, 231);
//   scrollbar-width: thin;
// }

// .style-15 {
//   scrollbar-color: rgba(0, 181, 226, .4) rgb(231, 231, 231);
//   scrollbar-width: thin;
// }

// .style-15::-webkit-scrollbar-track {
//   // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
//   background-color: #fff;
//   border-radius: 5px;
// }

// .style-15::-webkit-scrollbar {
//   width: 5px;
//   height: 5px;
//   background-color: #fff;
// }

// .style-15::-webkit-scrollbar-thumb {
//   border-radius: 5px;
//   background-color: rgba(0, 181, 226, .4);
//   opacity: .5;
// }

//----------------------------------------------

//Other classes
.tab-container {
  overflow-x: hidden;
}

//----------------------------------------------

//Stepper styles
.sticky-stepper {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  // position: fixed;
  top: 5.5em;
  background-color: white;
  margin-bottom: 2em;
  z-index: 101;
  transition: top 0.3s;
}

@media only screen and (max-width: 1600px) {
  .sticky-stepper {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 4.5em;
    background-color: white;
    margin-bottom: 2em;
    z-index: 101;
    transition: top 0.3s;
  }
}

@media only screen and (max-width: 995px) {
  .sticky-stepper {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 4em;
    background-color: white;
    margin-bottom: 2em;
    z-index: 101;
    transition: top 0.3s;
  }
}

.center-icon {
  background-color: $als-blue;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

button.center-icon:focused {
  border: none;
}

:host ::ng-deep.mat-mini-fab {
  outline: none !important;
  outline-style: none !important;
}

.mat-mini-fab {
  outline: none !important;
  outline-style: none !important;
}

:host ::ng-deep.mat-mdc-icon-button {
  outline: none !important;
  outline-style: none !important;

  mat-icon {
    position: relative;
    top: -3px;
  }
}

.mat-mdc-icon-button {
  outline: none !important;
  outline-style: none !important;

  mat-icon {
    position: relative;
    top: -3px;
  }
}

.center-text {
  text-align: center;
  margin-top: 1em;
}

.head-container {
  width: fit-content;
  background-color: $page-color;
  min-height: fit-content;
  text-align: center;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

// .section-container {
//   display: inline-block;
//   font-family: Roboto;
//   font-size: 12px;
//   line-height: 1.33;
//   border-radius: 4px;
//   padding-top: 0.7em;
//   padding-left: 0.7em;
//   padding-right: 0.7em;
// }

// .section-container:hover {
//   cursor: pointer;
//   background-color: $als-light-grey-15op;
// }

.section-body-active {
  background-color: $als-light-grey-15op;
}

.separator-line {
  display: inline-block;
  // background-color: $als-black;
  border-radius: 4px;
  border: solid 1px rgba(99, 102, 106, 0.3);
  background-color: rgba(99, 102, 106, 0.3);
  height: fit-content;
  width: 100%;
}

.separator-container {
  position: relative;
  bottom: 3.7em;
  display: inline-block;
  height: 2em;
  width: 3vw;
  // margin-left: 1em;
  // margin-right: 1em;
}

.noOutline {
  outline: none;
}

//-------------------------------------

//SCF section styles
h3,
h3.card-title {
  //Use h3 for headers of sections like headers for SCF or RSSWA
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: $als-black;
}

//----------------------------------------------

//Titles of section styles
.serialNo {
  width: 30%;
  margin-left: 1em;
}

@media only screen and (max-width: 1600px) {
  .serialNo {
    width: 100%;
    margin-left: 1em;
  }
}

.big-title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;
  color: $als-black;
}

.small-title {
  margin-left: 0.5em;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  color: $als-black;
}

.clock {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.43;
  color: $als-black;
}

//-------------------------------------

//Card styles
.card {
  // margin: .3em;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0 !important;
  box-shadow: 0 1px 4px 0 #dfdfdf;
  border: 1px solid #fff;
}

.card-footer {
  background-color: #FFF !important;
  min-height: 60px;
}

.card-header {
  padding: 1em 1em .75em 1em !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.075) !important;
}

.card-body {
  overflow: hidden;
}

.card-body-scroll {
  overflow-y: auto;
  max-height: 75vh;
}

.card-title {
  margin-bottom: 0 !important;
  font-size: large;
  font-weight: 500;
}

#toppage.card {
  margin-top: 1em;
}

//-------------------------------------

//Chip styles
.mat-chip {
  height: fit-content !important;
  word-wrap: break-word;
  word-break: break-all;
}

//-------------------------------------

//Berkeley Logo
img#logo {
  max-height: 4em;
  // width: 80%;
}

//-------------------------------------

.left-panel>* {
  float: left;
}

.nowrap,
.no-wrap {
  white-space: nowrap !important;
}

::ng-deep.hlt {
  background-color: #D57800;
  color: white;
  font-weight: 500;
  border-radius: 3px;
  padding: 0 3px;
}

.bold {
  font-weight: 600 !important;
}

::ng-deep.accent-tooltip {
  background-color: white;
  border: 1px solid $als-yellow;
  color: $als-yellow !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

::ng-deep.warn-tooltip {
  background-color: white;
  border: 1px solid $als-red;
  color: $als-red !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

::ng-deep.primary-tooltip {
  background-color: white;
  border: 1px solid $als-blue;
  color: $als-blue !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.max-width {
  max-width: max-content !important;
}

.pill {
  padding: 5px 12px;
  border-radius: 20px;
  font-size: small;
}


.toast-top-right {
  top: 100px !important;
  right: 10px;
}

.toast-container .ngx-toastr {
  width: 20vw !important;
}

.toast-container .ngx-toastr-banner {
  position: relative;
  overflow: hidden;
  margin: auto;
  padding: 15px 15px 15px 50px;
  width: 50vw;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
  background-color: #ffb451 !important;
  pointer-events: all;
}

.toast-center {
  top: 100px;
  left: calc(50% - 25vw);
}

.no-subscript {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.filter-icon {
  position: absolute;
  right: -6px;
}

.hlt {
  background-color: $als-orange;
  color: white;
  font-weight: 500;
  border-radius: 3px;
  padding: 0 3px;
}

.pointer {
  cursor: pointer;
}

.no-pointer {
  cursor: default !important;
}

// Form Field

.mat-mdc-text-field-wrapper.mdc-text-field {
  padding-left: 6px;
  padding-right: 6px;
}

.mat-mdc-form-field-infix {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 5px;
  min-height: 24px;
  z-index: 2;
}

.mat-mdc-floating-label {
  top: 22px !important;
}

.mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-29.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75)) !important;
  transform: var(--mat-mdc-form-field-label-transform) !important;
}

.mdc-text-field--outlined .mdc-notched-outline {
  background-color: white;
}

.ascc-tooltip,
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #005895;
  --mdc-plain-tooltip-supporting-text-color: white;
  --mdc-plain-tooltip-container-shape: 4px !important;
  --mdc-plain-tooltip-supporting-text-line-height: 1.2em !important;
  font-size: 0.8em;
  opacity: 1 !important;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  border-radius: var(--mdc-plain-tooltip-container-shape);
  opacity: 1 !important;
  box-shadow: 0 1px 5px #aaa;
}

.tooltip-wide .mdc-tooltip__surface {
  opacity: 1 !important;
  max-width: 70vw !important;
}

.tooltip-scroll {
  .mdc-tooltip__surface {
    max-height: 40vh;
    overflow-y: auto;
  }
}

.tooltip-date {
  font-weight: 400 !important;
  color: #74AA50;
  font-size: small !important;
}

// .mat-mdc-tooltip {
//   --mdc-plain-tooltip-container-color: #005895;
//   --mdc-plain-tooltip-supporting-text-color: white;
//   --mdc-plain-tooltip-container-shape: 4px;
//   --mdc-plain-tooltip-supporting-text-line-height: 1.2em;
//   font-size: .9em;
// }

.invisible {
  visibility: hidden !important;
  display: block;
  height: 0 !important;
  width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
}

.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-label-text-color: #ffffff;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: var(--mdc-dialog-container-color, white);
  overflow: hidden;
}

.card-footer {
  .mat-mdc-dialog-actions {
    padding: 0 !important;
    min-height: 0 !important;
  }
}

.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  opacity: 1 !important;
}

.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #eaaa00;
  --mdc-protected-button-label-text-color: #ffffff;
}

button-signature,
button-signature-radio,
checkbox-signature,
master-control {
  height: 100%;
  display: flex;
}

button:focus {
  outline: none;
}

.closex {
  position: absolute !important;
  top: 5px;
  right: 3px;
  height: 1.5em !important;
  width: 1.5em !important;
  line-height: 1em;
  font-size: 1em;
}

.closex mat-icon {
  position: relative;
  top: 4px;
  font-size: 1em;
}


.mat-mdc-slide-toggle .mdc-form-field .mdc-label {
  margin-bottom: 3px !important;
}

.mat-mdc-slide-toggle {
  margin-bottom: 3px !important;
}

// Hilite changes

$color-outline: $als-yellow;

mat-slide-toggle.hilite {
  --mdc-switch-disabled-selected-handle-color: #eaaa00;
  --mdc-switch-disabled-selected-track-color: #eaaa00;
  --mdc-switch-disabled-unselected-handle-color: #eaaa00;
  --mdc-switch-disabled-unselected-track-color: #eaaa00;

  .mdc-label {
    color: $als-yellow;
  }

  opacity: 1;
  border: 2px solid #e7a300;
  border-radius: 4px;
  padding: 3px 10px;
  position: relative;
  top: -5px;
}

mat-form-field {
  &.hilite {

    .mat-mdc-text-field-wrapper,
    .mdc-text-field--disabled {
      --mdc-outlined-text-field-outline-width: 2px !important;
      --mdc-outlined-text-field-outline-color: #eaaa00 !important;
      --mdc-outlined-text-field-label-text-color: #eaaa00 !important;
    }

    .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
    .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
    .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
      border-color: $als-yellow;
    }

    .mdc-notched-outline__leading {
      border-left: 2px solid;
      border-right: none;
      width: 12px;
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-top: 2px solid;
      border-bottom: 2px solid;
    }

    .mdc-notched-outline--notched .mdc-notched-outline__notch {
      border-top: none;
    }

    .mdc-notched-outline__trailing {
      border-right: 2px solid;
    }

    .mdc-text-field--outlined.mdc-text-field--disabled .mdc-floating-label,
    .mdc-text-field--outlined.mdc-text-field--disabled .mdc-floating-label--float-above {
      color: $als-yellow;
    }
  }
}

mat-form-field {
  &.hilite {
    &.hilited-input {

      ::ng-deep .mat-form-field-outline-start,
      ::ng-deep .mat-form-field-outline-gap,
      ::ng-deep .mat-form-field-outline-end {
        background-color: #f5d580 !important;
      }
    }

    // to change border color
    ::ng-deep .mat-form-field-outline {
      color: $color-outline !important;
    }

    // to change label color
    ::ng-deep .mat-form-field-label {
      color: $color-outline !important;
    }
  }
}

mat-slide-toggle {
  &.hilite {
    opacity: 1;

    ::ng-deep .mat-slide-toggle-label {
      border: 1px solid $als-yellow;
      border-radius: 4px;
      padding: 15px;
    }
  }
}

.mat-container {
  &.hilite {
    border: 2px solid $als-yellow !important;
  }
}

mat-radio-group {
  &.hilite {
    opacity: 1;
    border: 1px solid $als-yellow;
    border-radius: 4px;
    padding: 30px 10px 5px 10px;
  }
}

mat-checkbox {
  &.hilite {
    .mdc-form-field {
      color: $als-yellow;
      border: 2px solid $als-yellow;
      border-radius: 4px;

      .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
        color: $als-yellow;
      }
    }

    ::ng-deep.mdc-label {
      padding-left: 6px;
      padding-right: 6px;
      margin-bottom: 0 !important;
    }
  }
}

mat-cell {
  &.hilite {
    background-color: $als-yellow;
  }
}

mat-row {
  &.hilite {
    background-color: $als-yellow !important;
  }
}

fieldset.field-group {
  border: 1px solid #ddd;
  border-radius: 3px !important;
  padding: 6px !important;
  position: relative !important;

  &.hilite {
    border: 2px solid $als-yellow !important;

    legend {
      color: $als-yellow !important;
    }
  }

  legend {
    width: unset !important;
    max-width: 50% !important;
    padding: 0 5px !important;
    margin-bottom: 0 !important;
    font-size: 11px !important;
    margin-left: 5px !important;
  }

  &.disabled {
    legend {
      color: #a8a8a8 !important;
    }
  }

  &.invalid {
    border: 1px solid $als-red !important;

    legend {
      color: $als-red !important;
    }
  }
}

div.hilite {
  border: 2px solid $als-yellow !important;
}

// fieldset:hover,
// .field-group:hover {
//   &.hilite {
//     border: 2px solid $als-yellow;
//   }
// }



.background-yellow {
  background-color: #f5d580;
}

.buttons-amendment {

  border-left: 3px solid $als-yellow;
  background-color: #f9e6b3;

}

sidenav-content-buttons,
.buttons {
  position: absolute;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #ddd;
  padding: 10px;
  box-shadow: none;
  width: 100%;
  overflow: hidden;
  z-index: 200;
}

sidenav-content-buttons,
.buttons:empty:not(:has(button)) {
  display: none;
}

.mat-mdc-optgroup-label {
  color: $als-blue;
  opacity: .8;
  font-size: .85em;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background-color: $hover-color !important;
}

.type-header {
  padding-top: 14px;
  max-width: 20vw;
}

// .field-group {
//   border: 1px solid #ddd;
//   border-radius: 3px;
//   padding-left: 6px;
//   padding-right: 6px;
//   padding-top: 10px;
//   position: relative;
//   min-height: 45px;
// }

// .field-group:hover {
//   border: 1px solid #000;
// }

// .field-group-label {
//   font-size: 11px;
//   position: absolute;
//   top: -8px;
//   left: 9px;
//   color: #888;
//   background-color: #fff;
//   padding-left: 4px;
//   padding-right: 4px;
// }

.section {
  // padding: 1em;
  // box-shadow: 0 0 3px grey;
  // border-radius: 3px;
  height: 100%;
  background-color: #FFF;
}

.mat-mdc-radio-button .mdc-form-field {
  display: flex;
  align-items: flex-end;
}

.mat-mdc-radio-button {
  height: auto;
  display: block;
}

.mdc-button .mdc-button__label {
  position: relative;
  white-space: nowrap;
}

.relative {
  position: relative;
}

.mat-datepicker-popup {
  background-color: white;
  box-shadow: 0 0 3px 0;
  border-radius: 4px;
}

// ::ng-deep.mat-sort-header-pointer-middle {
//   margin: auto;
//   height: 2px;
//   width: 2px;
//   background: white;
//   transform: rotate(45deg);
// }

// ::ng-deep.mat-sort-header-pointer-left,
// ::ng-deep.mat-sort-header-pointer-right {
//   background: white;
//   width: 6px;
//   height: 2px;
//   position: absolute;
//   top: 0;
// }

// ::ng-deep.mat-sort-header-pointer-left,
// ::ng-deep.mat-sort-header-pointer-right {
//   background: white;
//   width: 6px;
//   height: 2px;
//   position: absolute;
//   top: 0;
// }

.small-icon-button {
  width: 24px !important;
  height: 24px !important;
  // padding: 0 !important;

  mat-icon {
    position: relative;
    top: -10px;
    left: -7px;
  }
}

.input-hidden {
  visibility: hidden;
  height: 0 !important;
}

.note {
  margin: 0.5em;
  box-shadow: 0 2px 0.3em rgba(0, 0, 0, .5);
  overflow-x: hidden;
  overflow-wrap: break-word;
}

.notes {
  background-color: transparent !important;
  box-shadow: none !important;
}

#left-nav-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
}

mat-hint,
.mat-hint {
  color: gray;
  font-size: 12px;
}

.mat-mdc-form-field-error {
  color: $als-red;
  font-size: 12px;
}

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
  color: $als-black !important;
}

.mdc-tab--active .mdc-tab__content .mdc-tab__text-label {
  color: white !important;
}

.mdc-tab--active .mdc-tab-indicator {
  background-color: #006BA6;
  border-radius: 3px 3px 0 0;
  border: 1px solid white;
}

.mdc-tab--active .mdc-tab-indicator :hover {
  opacity: .75;
}

.mat-mdc-tab-links,
.mat-mdc-tab-labels {
  border-bottom: 2px solid $als-blue;
}

.max-60vh {
  max-height: 60vh;
  overflow: auto;
}

.border-none {
  border: none !important;
}

.checkbox-container .mat-mdc-checkbox .mdc-form-field .mdc-label {
  white-space: nowrap;
  position: relative;
  top: -3px;
}

::ng-deep.role-chip {
  padding: 2px 8px 0 8px !important;
  margin: 2px !important;
  min-height: 19px !important;
  height: 19px !important;
  font-size: 11px !important;
  margin-top: 2px !important;
  font-weight: 400;
  background-color: #0094e5 !important;
  color: #fff !important;
  white-space: nowrap;
  min-width: 30px !important;

  ::ng-deep.mat-icon {
    width: 14px !important;
    height: 20px !important;
    color: #fff !important;
    opacity: .7 !important;
    margin-left: 3px !important;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    top: -1px;
  }

  ::ng-deep.mat-mdc-chip-action {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ::ng-deep.mat-mdc-chip-action-label {
    color: white !important;
  }
}

.role-option {
  min-height: 30px !important;

  .mdc-list-item__primary-text {
    width: 100% !important;
  }
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.mat-mdc-menu-panel {
  max-width: 340px !important;
}

.strikeout,
.line-through {
  text-decoration: line-through;
  opacity: .5;
}


.mdc-notched-outline {
  border-radius: 4px !important;
}

.chip {
  background-color: rgb(224, 224, 224);
  padding: 5px 10px;
  border-radius: 30px;
  font-size: small;
  white-space: nowrap;
  margin: 2px;
}


// mat-calendar
.mat-calendar-controls {
  display: flex;
  align-items: center;
  margin: 0 !important;
}

.mat-datepicker-content .mat-calendar {
  min-width: 300px;
  height: unset !important;
}

.overflowX-hidden {
  overflow-x: hidden;
}

ol li,
ul li {
  padding-left: .5em !important;
}

.mat-icon {
  overflow: visible !important;
}

.btn-link {
  pointer-events: all !important;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  box-shadow: none !important;
}

.btn-link,
.btn-link:hover {
  color: white !important;
}

/* styles.css */
.dialog-container {
  position: relative;
  display: flex;
  flex-direction: column;
  /* Optional: initial size */
}

.resizer {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  background: red;
  /* Change this to match your design */
}

mat-dialog-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

mat-dialog-content>div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.diagonal-hidden {
  clip-path: polygon(90% 0, 90% 90%, 0 90%);
}

.ql-toolbar.ql-snow {
  border: none !important;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
  background-color: #efefef;
}

.comment-box a {
  pointer-events: all !important;
}

.transparent-backdrop {
  background-color: transparent !important;
}

.restriction-tooltip-row {
  display: flex;
  align-items: center;
  line-height: 2em;
}

.restriction-tooltip-row::not()last-of-type {
  border-bottom: 1px solid gray;
}

.mat-sort-header-arrow {
  color: $als-yellow !important;
}

.deleted-row {
  background-color: lighten($als-red, 30%) !important;
}

.deleted-row .mat-mdc-cell:first-of-type::before {
  font-family: "Material Icons";
  content: "do_not_disturb_on";
  display: inline-block;
  vertical-align: middle;
  margin-right: -2px;
  font-size: 20px;
  color: $als-red;
  position: relative;
  left: -4px;
}

.added-row {
  background-color: lighten($als-green, 30%) !important;
}

.added-row .mat-mdc-cell:first-of-type::before {
  font-family: "Material Icons";
  content: "add_circle";
  display: inline-block;
  vertical-align: middle;
  margin-right: -2px;
  font-size: 20px;
  color: $als-green;
  position: relative;
  left: -4px;
}

.modified-row {
  background-color: lighten($als-yellow, 30%) !important;
}

.modified-row .mat-mdc-cell:first-of-type::before {
  font-family: "Material Icons";
  content: "edit";
  display: inline-block;
  vertical-align: middle;
  margin-right: -2px;
  font-size: 20px;
  color: $als-yellow;
  position: relative;
  left: -4px;
}

.wait {
  cursor: wait;
}

::ng-deep.olog-menu {
  position: relative;
  left: -100px;
  top: -60px;
}